.popup {

  width: 440px;
  height: 596px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #171E27;
  padding: 32px;

  .popup_block {
    margin: 0 auto;
    width: 380px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;


    .close {
      margin-left: 300px;
      cursor: pointer;
    }

    span {

      font-family: "Prompt";
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      color: #FFFFFF;
    }

    div {
      padding: 0;
      margin: 0;
      text-align: center;

      p {
        padding: 0;
        margin: 0;
        width: 338px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #5E6B74;
        border: none;
      }

      .email {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #EDF1F4;
      }
    }

    .buttons_block {
      display: flex;
      align-items: center;


      button {
        width: 102px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 18px 16px 18px;
        background: #8A24F3;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
        outline: none;
        border: none;
      }

      .close_button {
        background: transparent;
        color: #8A24F3;
        border: none;
      }

    }
  }
}


@media screen and (max-width: 767px) {

  .popup {
    transform: translateY(15%);
    width: 328px;
    height: 488px;
    border-radius: 12px;
    padding: 20px;

    .popup_block {
      width: 288px;
      height: 380px;
      gap: 20px;


      .close {
        margin-left: auto;
      }

      span {

        font-family: "Prompt";
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;

      }

      div {
        text-align: center;
        padding: 0;
        margin: 0;

        p {
          padding: 0;
          margin: 0;
          width: 288px;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          text-align: center;
          color: #5E6B74;


        }

        .email {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          text-align: center;
          color: #EDF1F4;
        }
      }

      .buttons_block {
        display: flex;
        align-items: center;


        button {
          width: 102px;
          height: 56px;
          border-radius: 8px;
          padding: 16px 18px 16px 18px;
          background: #8A24F3;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #FFFFFF;
          text-align: center;
          cursor: pointer;
          outline: none;
          border: none;
        }

        .close_button {
          background: transparent;
          color: #8A24F3;
          border: none;
        }

      }
    }
  }


}