
img {

  margin-top: auto;
}

.popup_container {

  align-items: center;
  background: #000000B2;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.form_back {

  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 612px;
  border-radius: 32px;
  background: linear-gradient(83.11deg, rgba(138, 139, 142, 0.1) 3.83%, rgba(239, 239, 239, 0.1) 59.24%, rgba(84, 85, 88, 0.1) 77.71%);
  padding: 32px;
  overflow: hidden;
  margin-bottom: 120px;

  img {
    transform: translateX(32px);
  }


  form {
    padding-top: 32px;
    padding-left: 32px;
    width: auto;
    height: 548px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    h2 {
      width: 646px;
      height: 52px;
      font-family: "Prompt";
      font-weight: 500;
      font-size: 40px;
      line-height: 52px;
      letter-spacing: 0.4px;
      color: #FFFFFF;
      margin-bottom: 50px;
    }

    p {
      padding: 0  ;
    }

    .inputs_block {

      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .name_group, .phone_group {
        width: 646px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;

        input {
          width: 311px;
          height: 56px;
          border-radius: 8px;
          border: 1px solid #D9DEE2;
          padding: 16px;
          font-family: 'Roboto';
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.5px;
          outline: none;
          font-size: 16px;
          transition: all .1s ease;
          color: #171E27;
        }

        .label_group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0;

          label {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #5E6B74;
            padding-bottom: 16px;

          }

          .error_container {
            height: 6px;

            .error {
              padding: 5px;
              color: red;
              font-family: "Roboto";
              font-size: 0.8rem !important;
            }
          }
        }

      }
    }

    .submit_button {
      width: 646px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 0px 16px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7.59px;
      background: #8A24F3;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      border: none;
      cursor: pointer;
    }

    p {
      width: 646px;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #5E6B74;
      border: none;

    }
  }
}

span {

  font-family: "Roboto";
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.4px;
  text-align: center;
  color: black;
}

.error.visible {
  transform: scale(100%);
}

.button_disabled {

  width: 646px;
  height: 56px;
  border-radius: 8px;
  padding: 16px 0px 16px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.59px;
  background: #8A24F3;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  border: none;
  cursor: wait !important;
}



@media screen and (max-width: 767px) {


  .form_back {
    width: 340px;
    flex-direction: column;
    height: auto;
    gap: 0px;
    padding: 15px;
    margin-bottom: 40px;

    form {
      height: 800px;
      padding-left: 16px;
      width: 296px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0px;
      border-radius: 16px;


      h2 {
        width: 296px;
        height: 160px;
        font-family: 'Prompt';
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 0.27px;
        margin-bottom: 12px;
      }


      .inputs_block {

        width: 296px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        margin-bottom: 12px;


        .name_group, .phone_group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0px;

          input {
            width: 296px;
            height: 56px;
            border-radius: 8px;
            border: 1px solid #D9DEE2;
            padding: 16px;
            font-family: 'Roboto';
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.5px;
            outline: none;
            font-size: 16px;
            transition: all .1s ease;
            text-align: justify;
          }

          .label_group {

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            label {
              margin-left: 10px;
              transform: translateY(15%);
              font-family: 'Roboto';
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.5px;
              color: #5E6B74;
              padding-bottom: 16px;

            }

            .error_container {
              height: 6px;

              .error {
                padding: 5px;
                color: red;
                font-family: 'Roboto';
                font-size: 0.8rem !important;
              }
            }
          }

        }
      }

      .submit_button {

        width: 296px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 0px 16px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: #8A24F3;
        margin-bottom: 12px;
        margin-top: 16px;

      }

      p {

        transform: translateY(5%);
        width: 296px;
        height: 120px;
        font-weight: 400;
        line-height: 20px;
        color: #5E6B74;
        border: none;

      }




    }
  }

  span {

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.4px;
    text-align: center;
    color: black;
  }

  .button_disabled {

    width: 296px;
    height: 56px;
    border-radius: 8px;
    padding: 16px 0px 16px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #8A24F3;
    border: none;
    cursor: wait !important;
    margin-bottom: 12px;
    margin-top: 16px;
  }

  .bank_img {
    margin-top: auto;

    transform: translateY(17px)!important;

  }
}

