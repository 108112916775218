header {
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  &::after {

    background: url('../../assets/background.png') center/cover no-repeat;
    width: 1500px;
    height: 1180px;
    content: '';
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-60%, 0%);
    z-index: 1;

  }


  .menu_block {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 120px;


    img  {
      transform: translate(0px,0px);
      margin: 0;
      padding: 0;

    }

    .button_block {
      width: 281px;
      height: 58px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;

      .open_account {

        width: 133px;
        height: 40px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #919CA2;
        background: transparent;
        gap: 2px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: center;
        color: #D9DEE2;
        cursor: pointer;

      }
    }
  }
  .header_block_wrapped {
    width: 100%;
    height: 394px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    z-index: 10;

  .header_block {
    width: 100%;
    height: 106px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;


    h1 {
      width: 100%;
      font-family: 'Prompt';
      font-size: 48px;
      line-height: 62px;
      color: #FFFFFF;
    }

    span {

      font-family: 'Roboto';
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #919CA2;
    }

    .start_now_button {
      width: 180px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 0px 16px 0px;
      background: #8A24F3;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      border: none;
    }
  }
  }

  .vector_block {
    width: 100%;
    height: 494px;
    background: #252F3C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 24px 24px 0px 0px;
    margin-bottom: 120px;

    .up_block {
      padding: 32px;
      height: 154px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .up_div {
        display: flex;
        align-items: center;
        gap: 20px;

        span {
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 36px;
          line-height: 44px;
          color: #FFFFFF;
        }
      }
      .up_div2 {
        display: flex;
        flex-direction: column;
        align-items: center;


        .exchange_result {
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 36px;
          line-height: 44px;
          color: #FFFFFF;
        }
        .currency {
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #919CA2;
        }
      }
    }

    .down_block {

      width: 100%;
      height: 340px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #1E2732;

      .chat_area_block  {
        padding: 32px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .chat_area {
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #FFFFFF;
        }

        .day {

          height: 56px;
          border-radius: 12px;
          border: 1px solid #8A24F3;
          padding: 16px 36px 12px 36px;
          font-family: 'Prompt';
          font-weight: 500;
          font-size: 20px;
          line-height: 28px ;
          color: #FFFFFF;

        }
      }
      .img_block {


      }
    }

    .time_block {
      padding: 32px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .time_block_div {
        display: flex;
        flex-direction: column;

        .time {
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #FFFFFF;
        }

        .date {
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #919CA2;
        }
      }
    }
  }
}


@media screen and (max-width: 1660px) {

  header {
    position: relative;

    &::after {

      background: url('../../assets/background.png') center/cover no-repeat;
      content: '';
      position: absolute;
      width: 1460px;
      top: 0%;
      left: 50%;
      transform: translate(-58%, 0%);
      z-index: 1;


    }
  }

  }

@media screen and (max-width: 767px) {

  header {
    width: 328px;
    padding-top: 16px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    &::after {
      display: none;
      width: 0;
      height: 0;
    }

    .menu_block {
      width: 100%;
      align-items: center!important;
      padding: 0 16px 0 16px;
      gap: 12px;
      margin-bottom: 40px;

      .button_block {
        width: 281px;
        height: 58px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0;

        .open_account {

          width: 133px;
          height: 40px;
          border-radius: 6px;
          border: 1px solid #919CA2;
          background: transparent;
          gap: 2px;
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.4px;
          text-align: center;
          color: #D9DEE2;
          padding: 0px 4px 0px 4px;
          cursor: pointer;

        }
      }
    }

    .header_block {
      width: 100%;
      height: 108px;
      padding: 0 16px 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;


      h1 {
        width: 100%;
        font-family: 'Prompt';
        font-size: 32px;
        line-height: 40px;
        color: #FFFFFF;
        align-self: start;
      }

      span {
        width: 100%;
        height: 48px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #919CA2;
        text-align: start;
        margin: 0;
        padding: 0;
      }

      .start_now_button {
        width: 180px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 0px 16px 0px;
        background: #8A24F3;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
        border: none;
      }
    }
    .background {
      transform: translateY(-20%);
      pointer-events: none;
      height: 400px;
      margin-bottom: 40px;

      img {
        pointer-events: none;
      }
    }


    .vector_block {
      width: 328px;
      height: 432px;
      background: #252F3C;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 24px 24px 24px 24px;
      margin-bottom: 40px;
      overflow: hidden;
      z-index: 1;
      margin-bottom: 40px;

      .up_block {
        height: 92px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .up_div {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            margin: 0;
            transform: translateY(0)!important;



          }
          span {
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
          }
        }
        .up_div2 {
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;


          .exchange_result {
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            color: #FFFFFF;
          }
          .currency {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #919CA2;
          }
        }
      }

      .down_block {

        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #1E2732;
        overflow: hidden;



        .chat_area_block  {
          margin-top: 40px;
          height: 46px;
          padding: 32px;
          display: flex;
          justify-content: space-between;
          align-items:center;


          .chat_area {
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
          }

          .day {
            width: 85px;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid #8A24F3;
            padding: 12px 20px 10px 20px;



            span {

              font-family: 'Prompt';
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              text-align:center;
              color: #FFFFFF;
            }

          }
        }
        .img_block {


        }
      }

      .time_block {
        padding: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .time_block_div {
          display: flex;
          flex-direction: column;

          .time {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #FFFFFF;
          }

          .date {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #919CA2;
          }
        }
      }
    }
  }
}