footer {
  display: flex;
  flex-direction: column;
  justify-content: center;


}

@media screen and (max-width: 767px) {
  footer {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

}
}